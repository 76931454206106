import React, { useEffect, useState } from 'react';
import { Gantt } from 'gantt-task-react';
import "gantt-task-react/dist/index.css";
import ModalTaskGantt from "./ModalTaskGantt";
import { escapeHtml, rescueHtml } from "./Tools";
import axios from 'axios';
import qs from 'qs';

const GanttSyntheseProjets = (props) =>
{
    const purple = '#6571FE'
    const green = '#59B44A'

    const [data, setData] = useState(undefined);
    const [modalTask, setModalTask] = useState(false);
    const [addupdate, setAddupdate] = useState(undefined);
    const [view, setView] = useState(undefined);
    const [aff, setAff] = useState(false);

    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');
    const [type, setType] = useState('');
    const [progress, setProgress] = useState(0);
    const [color, setColor] = useState('');
    const [commentaire, setCommentaire] = useState('');
    const [dependencies, setDependencies] = useState('');

    async function getDatas(listeProjets)
    {
        let tmp = [];
        let key = 0;
        for (const element of listeProjets) {
            /* Filtre les projets de l'hypothese actuelle */
            if (element[23 + parseInt(props.user.hypothese)] == 1) {
                try {
                    const result = await axios.post('https://app-slfinance.fr/api/gantt.php', qs.stringify({
                        'getStartEnd': true,
                        'id_projet': element[0]
                    }));
    
                    // if (result.data.start && result.data.end) {
                        tmp.push({
                            id: key,
                            start: result.data.start?new Date(result.data.start):new Date(),
                            end: result.data.end?new Date(result.data.end):new Date(),
                            name: !result.data.start || !result.data.end ?rescueHtml(element[3])+' (GANTT non renseigné)':rescueHtml(element[3]),
                            type: 'task',
                            styles: { 
                                backgroundColor: element[27] && element[27] !== '#ffffff'?element[27]:'#bdc3c7', 
                                backgroundSelectedColor: element[27] && element[27] !== '#ffffff'?element[27]:'#bdc3c7', 
                                progressColor: element[27] && element[27] !== '#ffffff'?element[27]:'#bdc3c7', 
                                progressSelectedColor: element[27] && element[27] !== '#ffffff'?element[27]:'#bdc3c7' 
                            },
                        });
                    // }
                } catch (error) {
                    console.error('Erreur lors de la récupération des données:', error);
                }
            }
            key++;
        }
    
        setData(tmp);
        setAff(!aff);
        console.log(tmp);
    }


    useEffect(() => {
        if (props.user) {
            getDatas(props.listeProjets);
        }
    }, [])


	return (
        <div style={{}}>

            {props.user && addupdate?
            <ModalTaskGantt
            modalVisible={modalTask}
            setModalVisible={setModalTask}
            id_budget={props.user.id_budget}
            id_projet={props.id_projet}
            getDatas={getDatas}
            addupdate={addupdate}
            id={id}
            name={name}
            start={start}
            end={end}
            type={type}
            progress={progress}
            color={color}
            commentaire={commentaire}
            dependencies={dependencies[0]}
            tasks={data}
            />
            :null}

            <div style={{display: 'flex'}}>

                <div style={{flex: 1}}></div>

                <div style={{display: 'flex'}}>
                    <div style={{flex: 1}}></div>
                    <button
                        className='shadow-md rounded-md'
                        style={view === 'Day'?{backgroundColor: purple, color: 'white', margin: 5, fontSize: 17, alignItems: 'center', justifyContent: 'center', display: 'flex', paddingLeft: 10, paddingRight: 10}:{backgroundColor: 'white', color: purple, border: '1px solid '+purple, margin: 5, fontSize: 17, alignItems: 'center', justifyContent: 'center', display: 'flex', paddingLeft: 10, paddingRight: 10}}
                        onClick={async () => {
                            await setView('Day');
                        }}
                    >
                        <p style={{}}>Jours</p>
                    </button>
                    <button
                        className='shadow-md rounded-md'
                        style={view === 'Month'?{backgroundColor: purple, color: 'white', margin: 5, fontSize: 17, alignItems: 'center', justifyContent: 'center', display: 'flex', paddingLeft: 10, paddingRight: 10}:{backgroundColor: 'white', color: purple, border: '1px solid '+purple, margin: 5, fontSize: 17, alignItems: 'center', justifyContent: 'center', display: 'flex', paddingLeft: 10, paddingRight: 10}}
                        onClick={async () => {
                            await setView('Month');
                        }}
                    >
                        <p style={{}}>Mois</p>
                    </button>
                    <button
                        className='shadow-md rounded-md'
                        style={!view || view === 'Year'?{backgroundColor: purple, color: 'white', margin: 5, fontSize: 17, alignItems: 'center', justifyContent: 'center', display: 'flex', paddingLeft: 10, paddingRight: 10}:{backgroundColor: 'white', color: purple, border: '1px solid '+purple, margin: 5, fontSize: 17, alignItems: 'center', justifyContent: 'center', display: 'flex', paddingLeft: 10, paddingRight: 10}}
                        onClick={async () => {
                            await setView('Year');
                        }}
                    >
                        <p style={{}}>Années</p>
                    </button>
                    <div style={{flex: 1}}></div>
                </div>
            </div>

            <div style={{margin: 5}}>
                {data && data.length !== 0 && props.user?
                <Gantt
                todayColor={'#c8eae9'}
                handleWidth={5}
                rowHeight={35}
                tasks={data}
                locale={'fr'}
                preStepsCount={0}
                columnWidth={view === 'Day'?60:view === 'Month'?180:view === 'Year'?250:250}
                TaskListHeader={
                    () => (
                        <div style={{flex: 1, display: 'flex', backgroundColor: '#f5f5f5', alignItems: 'center', justifyContent: 'center', textAlign: 'center', minWidth: 450, minHeight: 50, borderBottom: '1px solid white', borderLeft: '1px solid white', borderTop: '1px solid white'}}>
                            <span style={{flex: 1}}>Intitulé</span>
                            <span style={{flex: 0.5}}>Debut</span>
                            <span style={{flex: 0.5}}>Fin</span>
                        </div>
                    )
                }
                TaskListTable={
                    (e) => (
                        e.tasks.map((task) =>
                        <div key={task.id} style={{display: 'flex', backgroundColor: '#f5f5f5', alignItems: 'center', justifyContent: 'center', minHeight: 35, borderLeft: '1px solid white', borderBottom: '1px solid white'}}>
                            <span style={{flex: 1, fontSize: 14}}>{task.name.substring(0, 25)+(task.name.length>25?'..':'')}</span>
                            <span style={{flex: 0.5, fontSize: 14}}>{task.start.getDate()<10?'0'+task.start.getDate():task.start.getDate()}/{(task.start.getMonth()+1)<10?'0'+(task.start.getMonth()+1):(task.start.getMonth()+1)}/{task.start.getFullYear()}</span>
                            <span style={{flex: 0.5, fontSize: 14}}>{task.end.getDate()<10?'0'+task.end.getDate():task.end.getDate()}/{(task.end.getMonth()+1)<10?'0'+(task.end.getMonth()+1):(task.end.getMonth()+1)}/{task.end.getFullYear()}</span>
                        </div>
                    ))
                }
                viewMode={
                    view?
                        view === 'Month'?
                            'Month'
                            :
                            view === 'Year'?
                                'Year'
                                :
                                view === 'Hour'?
                                'Hour'
                                :
                                'Day'
                        :
                        'Year'
                }
                />
                :
                <div style={{marginTop: 50}}>
                    <p>Aucune fiche projet n'a de GANTT renseigné.</p>
                </div>
                }
            </div>

        </div>
	);
}

export default GanttSyntheseProjets
;
