import React, { useEffect, useState } from 'react';
import Navbar from "./components/Navbar";
import Access from "./components/Access";
import BounceLoader from "react-spinners/BounceLoader";
import axios from 'axios';
import qs from 'qs';
import { addSpace, capitalizeFirstLetter } from './components/Tools';
import {NotificationManager} from 'react-notifications';

const Restitution = (props) =>
{
    const heightObject = window.innerHeight/1.5
    const [objet, setObjet] = useState([]);
    const [annees, setAnnees] = useState([]);
    let retroprosp = window.location.pathname.split('/')[2];
    let fctinv = window.location.pathname.split('/')[3];
    

    const purple = '#6571FE'
    const blue = '#069BD3'


    async function validation(datas_tmp, hyp)
    {
        var ret = true
        var tmpi = 0
        var tab_tmp = {}
        while(tmpi <= datas_tmp.length / 10)
        {
            tab_tmp[tmpi] = datas_tmp.slice(0 + (tmpi * 10), 10 + (tmpi * 10))
            const params = {
                'update': true,
                'hypothese': hyp,
                'id_budget': props.user.id_budget,
                'fctinv': fctinv,
                'grille': tab_tmp[tmpi]
            }

            await axios.post("https://app-slfinance.fr/api/datas_restitution.php", qs.stringify(params))
            .catch((err) => {
                ret = false
                console.log(err)
            })
            tmpi++;
        }
        return ret;
    }


    async function mount() {
        console.log(props)
        var hyp = null;
        var grille_temporaire = ''

        if (props.user.nomen === 'M14' || props.user.nomen === 'M57') {
            if (retroprosp === 'retrospective') {
                hyp = 'R'
            }
            else {
                hyp = 'h'+props.user.hypothese;
            }

            if (fctinv === 'fct') {
                if (props.user.id_organisme_type === '1') {
                    grille_temporaire = 'Com'
                }
                else if (props.user.id_organisme_type === '2') {
                    grille_temporaire = 'Epci'
                }
                else if (props.user.id_organisme_type === '3') {
                    grille_temporaire = 'Com'
                }
            }
            else if (fctinv === 'inv') {
                if (retroprosp === 'retrospective') {
                    grille_temporaire = 'Retro'
                }
                else if (retroprosp === 'prospective') {
                    grille_temporaire = 'Prosp'
                }
            }

            if (fctinv === 'fct') {
                grille_temporaire += 'Fct'
            }
            else if (fctinv === 'inv') {
                grille_temporaire += 'Inv'
            }
        }
        
        // Recuperation des annees de retro et prosp
        let retro_an_deb = ''
        let retro_an_fin = ''
        let prosp_an_fin = ''
        let nomen = ''
        await axios.get('https://app-slfinance.fr/api/config_budget.php?id_budget='+props.user.id_budget)
        .then((result) => {
            result.data.forEach(element => {
                if (element[2] === 'retro_an_deb') {
                    retro_an_deb = parseInt(element[3]);
                }
                if (element[2] === 'retro_an_fin') {
                    retro_an_fin = parseInt(element[3]);
                }
                if (element[2] === 'prosp_an_fin') {
                    prosp_an_fin = parseInt(element[3]);
                }
                if (element[2] === 'nomen') {
                    nomen = element[3].split(';')[0];
                }
            });
        }).catch((err) => {console.log(err)})

        
        if (props.user.nomen === 'M4' || props.user.nomen === 'M41' || props.user.nomen === 'M43' || props.user.nomen === 'M49')
        {
            grille_temporaire = nomen;

            if (retroprosp === 'retrospective') {
                grille_temporaire += 'Retro'
            }
            else if (retroprosp === 'prospective') {
                grille_temporaire += 'Prosp'
            }
            if (fctinv === 'fct') {
                grille_temporaire += 'Fct'
            }
            else if (fctinv === 'inv')
            {
                grille_temporaire += 'Inv'
            }
        }
        
        if (retro_an_deb === '' || retro_an_fin === '' || prosp_an_fin === '') {
            await axios.get('https://app-slfinance.fr/api/application.php?access_token=StrategiesLocales')
            .then(async function (res) {
                if (res.data.prosp_an_deb && res.data.prosp_an_fin) {
                    if (retro_an_deb === '') {
                        retro_an_deb = parseInt(res.data.retro_an_deb)
                    }
                    if (retro_an_fin === '') {
                        retro_an_fin = parseInt(res.data.retro_an_fin)
                    }
                    if (prosp_an_fin === '') {
                        prosp_an_fin = parseInt(res.data.prosp_an_fin)
                    }
                }
            })
            .catch((err) => {
                console.log(err)
            })
        }
        var liste_an = []
        var i = 0
        if (retroprosp === 'retrospective') {
            while (retro_an_deb <= retro_an_fin)
            {
                liste_an[i] = parseInt(retro_an_deb)
                retro_an_deb++;
                i++;
            }
        }
        else {
            while (retro_an_fin <= prosp_an_fin)
            {
                liste_an[i] = parseInt(retro_an_fin)
                retro_an_fin++;
                i++;
            }
        }
        await setAnnees(liste_an)

        const formvars = {
            'getRestitution': true,
            'grille': grille_temporaire,
            'annees': liste_an,
            'id_budget': props.user.id_budget,
            'id_organisme': props.user.id_organisme
        }
        
        if (retroprosp === 'retrospective') {
            formvars.hypothese = 'R'
        }
        else {
            formvars.hypothese = 'H'+props.user.hypothese
        }

        await axios.post('https://app-slfinance.fr/api/restitution.php', qs.stringify(formvars))
        .then(async(ret) => {
            console.log(ret.data)
            NotificationManager.success("Grille sauvegardée", '', 700);
            if (ret.data.length != 0) {
                await setObjet(ret.data);
                await validation(ret.data, formvars.hypothese)
                .then(() => {
                    NotificationManager.success("Grille sauvegardée", '', 700);
                })
            }
            else {
                NotificationManager.warning("Absence de données", '', 3000);
            }
        })
    }


    // Récuperation de la liste des fiches projets
    useEffect(() => {
        if (props.user === null) {
            props.getUser()
        }
        else {
            mount();
        }
        
    }, [props.user])

    
	return (
        <div>
            <Access needConnection={true} type_user={[0]}>
                <Navbar user={props.user} />
                <div style={{display: 'flex'}}>
                    <div style={{flex: 1, margin: 20, display: 'flex'}}>
                        <p>Restitution</p>
                        <p style={{marginLeft: 5}}>\</p>
                        <p style={{marginLeft: 5}}>{capitalizeFirstLetter(retroprosp)}</p>
                        <p style={{marginLeft: 5}}>\</p>
                        <p style={{marginLeft: 5, color: purple, fontWeight: 'bold'}}>{fctinv === 'fct'?'Fonctionnement':'Investissement'}</p>
                    </div>
                    {props.user?
                    <p style={{flex: 1, textAlign: 'center', margin: 20}}>{props.user.nom_budget}</p>
                    :null}
                    
                    <div style={{flex: 1, margin: 20, marginRight: 30, textAlign: 'right'}}>
                        {/* <button
                        className='shadow-md p-2 rounded-md'
                        style={{backgroundColor: purple, color: 'white', fontSize: 14}}
                        onClick={() => {
                        }}>
                            Calcul automatique des emprunts d'équilibre
                        </button> */}
                    </div>
                    
                </div>
                {/* Grille Restitution */}
                {objet.length != 0?
                <div className='shadow-xl rounded-md' style={{margin: 20}}>
                    
                    {/* Premiere ligne "Montants en €" suivi des années*/}
                    <div style={{display: 'flex', textAlign: 'center', overflowY: 'scroll'}}>
                        <div style={{flex: 3, padding: 3, backgroundColor: blue, textAlign: 'center'}}>
                            <p style={{color: 'white', fontSize: 20}}>Montants en €</p>
                        </div>

                        {annees.map((an, j) =>
                        <div key={j} style={{flex: 1, display: 'flex'}}>
                            <div style={{flex: 1, padding: 3, backgroundColor: blue, borderLeft: '1px solid white', color: 'white', fontSize: 18}}>
                                <p>{an}</p>
                            </div>
                        </div>
                        )}
                    </div>


                    
                    {/* Liste de la grille avec les données */}
                    <div style={{overflowY: 'scroll', maxHeight: heightObject}}>
                        {objet.map((line, i) =>
                        <div key={i} style={{borderTop: '1px solid white'}}>
                            <div style={{display: 'flex'}}>
                                <div style={line[0].chapitre === '1'?{flex: 3, display: 'flex', padding: 3, backgroundColor: blue}:line[0].indentation === '1'?{flex: 3, display: 'flex', padding: 3}:{flex: 3, display: 'flex', padding: 3, fontSize: 14, fontStyle: 'italic'}}>
                                    <p style={line[0].indentation === '1'?line[0].chapitre === '1'?{color: 'white'}:{}:{marginLeft: 50}}>{line[0].intitule}</p>
                                </div>
                                {line.map((an, j) =>
                                j !== 0?
                                <div key={j} style={{flex: 1, display: 'flex'}}>
                                    <div style={line[0].chapitre === '1'?{flex: 1, padding: 3, backgroundColor: blue, borderLeft: '1px solid white', color: 'white', textAlign: 'right'}:line[0].indentation === '1'?{flex: 1, padding: 3, borderLeft: '1px solid white', textAlign: 'right'}:{flex: 1, padding: 3, borderLeft: '1px solid white', textAlign: 'right', fontSize: 13, fontStyle: 'italic'}}>
                                        {line[0]['matricule'] === 'RetroInv130'
                                        || line[0]['matricule'] === 'ProspInv130'
                                        || line[0]['matricule'] === 'M4RetroInv130'
                                        || line[0]['matricule'] === 'M4ProspInv115'
                                        || line[0]['matricule'] === 'M41RetroInv130'
                                        || line[0]['matricule'] === 'M41ProspInv115'
                                        || line[0]['matricule'] === 'M43RetroInv130'
                                        || line[0]['matricule'] === 'M43ProspInv115'
                                        || line[0]['matricule'] === 'M49RetroInv130'
                                        || line[0]['matricule'] === 'M49ProspInv115'?
                                        <p>{an[1] === 0?null:addSpace(Math.round(an[1]*100)/100, true)} an(s)</p>
                                        :null}

                                        {line[0]['matricule'] === 'RetroInv135'
                                        || line[0]['matricule'] === 'ProspInv135'
                                        || line[0]['matricule'] === 'M4RetroInv135'
                                        || line[0]['matricule'] === 'M4ProspInv120'
                                        || line[0]['matricule'] === 'M41RetroInv135'
                                        || line[0]['matricule'] === 'M41ProspInv120'
                                        || line[0]['matricule'] === 'M43RetroInv135'
                                        || line[0]['matricule'] === 'M43ProspInv120'
                                        || line[0]['matricule'] === 'M49RetroInv135'
                                        || line[0]['matricule'] === 'M49ProspInv120'?
                                        <p>{an[1] === 0?null:addSpace(Math.round(an[1]), true)} %</p>
                                        :null}

                                        {line[0]['matricule'] === 'RetroInv140'
                                        || line[0]['matricule'] === 'ProspInv140'
                                        || line[0]['matricule'] === 'M4RetroInv140'
                                        || line[0]['matricule'] === 'M4ProspInv125'
                                        || line[0]['matricule'] === 'M41RetroInv140'
                                        || line[0]['matricule'] === 'M41ProspInv125'
                                        || line[0]['matricule'] === 'M43RetroInv140'
                                        || line[0]['matricule'] === 'M43ProspInv125'
                                        || line[0]['matricule'] === 'M49RetroInv140'
                                        || line[0]['matricule'] === 'M49ProspInv125'?
                                        <p>{an[1] === 0?null:addSpace(Math.round(an[1]), true)} %</p>
                                        :null}

                                        {line[0]['matricule'] === 'RetroInv145'
                                        || line[0]['matricule'] === 'ProspInv145'
                                        || line[0]['matricule'] === 'M4RetroInv145'
                                        || line[0]['matricule'] === 'M4ProspInv130'
                                        || line[0]['matricule'] === 'M41RetroInv145'
                                        || line[0]['matricule'] === 'M41ProspInv130'
                                        || line[0]['matricule'] === 'M43RetroInv145'
                                        || line[0]['matricule'] === 'M43ProspInv130'
                                        || line[0]['matricule'] === 'M49RetroInv145'
                                        || line[0]['matricule'] === 'M49ProspInv130'?
                                        <p>{an[1] === 0?null:addSpace(Math.round(an[1]), true)} jour(s)</p>
                                        :
                                        null}

                                        {line[0]['matricule'] !== 'RetroInv130'
                                        && line[0]['matricule'] !== 'RetroInv135'
                                        && line[0]['matricule'] !== 'RetroInv140'
                                        && line[0]['matricule'] !== 'RetroInv145'
                                        && line[0]['matricule'] !== 'ProspInv130'
                                        && line[0]['matricule'] !== 'ProspInv135'
                                        && line[0]['matricule'] !== 'ProspInv140'
                                        && line[0]['matricule'] !== 'ProspInv145'

                                        && line[0]['matricule'] !== 'M4RetroInv130'
                                        && line[0]['matricule'] !== 'M4RetroInv135'
                                        && line[0]['matricule'] !== 'M4RetroInv140'
                                        && line[0]['matricule'] !== 'M4RetroInv145'
                                        && line[0]['matricule'] !== 'M4ProspInv115'
                                        && line[0]['matricule'] !== 'M4ProspInv120'
                                        && line[0]['matricule'] !== 'M4ProspInv125'
                                        && line[0]['matricule'] !== 'M4ProspInv130'

                                        && line[0]['matricule'] !== 'M41RetroInv130'
                                        && line[0]['matricule'] !== 'M41RetroInv135'
                                        && line[0]['matricule'] !== 'M41RetroInv140'
                                        && line[0]['matricule'] !== 'M41RetroInv145'
                                        && line[0]['matricule'] !== 'M41ProspInv115'
                                        && line[0]['matricule'] !== 'M41ProspInv120'
                                        && line[0]['matricule'] !== 'M41ProspInv125'
                                        && line[0]['matricule'] !== 'M41ProspInv130'

                                        && line[0]['matricule'] !== 'M43RetroInv130'
                                        && line[0]['matricule'] !== 'M43RetroInv135'
                                        && line[0]['matricule'] !== 'M43RetroInv140'
                                        && line[0]['matricule'] !== 'M43RetroInv145'
                                        && line[0]['matricule'] !== 'M43ProspInv115'
                                        && line[0]['matricule'] !== 'M43ProspInv120'
                                        && line[0]['matricule'] !== 'M43ProspInv125'
                                        && line[0]['matricule'] !== 'M43ProspInv130'

                                        && line[0]['matricule'] !== 'M49RetroInv130'
                                        && line[0]['matricule'] !== 'M49RetroInv135'
                                        && line[0]['matricule'] !== 'M49RetroInv140'
                                        && line[0]['matricule'] !== 'M49RetroInv145'
                                        && line[0]['matricule'] !== 'M49ProspInv115'
                                        && line[0]['matricule'] !== 'M49ProspInv120'
                                        && line[0]['matricule'] !== 'M49ProspInv125'
                                        && line[0]['matricule'] !== 'M49ProspInv130'
                                        ?
                                        <p>{an[1] === 0?null:addSpace(Math.round(an[1]), true)}</p>
                                        :null}

                                    </div>
                                </div>
                                :null)}
                            </div>
                        </div>
                        )}

                    </div>

                </div>
                :
                <div style={{flex: 1, margin: 100, display: 'flex'}}>
                    <div style={{flex: 1}}></div>
                    <BounceLoader color={purple} />
                    <div style={{flex: 1}}></div>
                </div>}
                <div style={{height: 50}}></div>
            </Access>
        </div>
	);
}

export default Restitution;
