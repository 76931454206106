import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import qs from 'qs';
import { ReactComponent as Check } from '../Img/icon-check.svg';
import { ReactComponent as Trash } from '../Img/icon-trash.svg';
import Dialog from "./Dialog";


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        paddingLeft: 75,
        paddingRight: 75,
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        overflow: 'hidden',
        background: "linear-gradient(#FAFAFA, #FFFFFF)"
    },
};


const ModalGestionPPR = (props) =>
{
    const green = '#59B44A'
    const purple = '#6571FE'
    const blue = '#069BD3'
    
    const [aff, setAff] = useState(false);
    const [ppr, setPpr] = useState('');
    const [pprToSuppress, setPprToSuppress] = useState('');
    
    const [dialogModalVisible, setDialogModalVisible] = useState(false);
    
    useEffect(() => {
    }, [props])

    function callbackDialog(ret, value) {
        if (ret === true) {
            suppress();
        }
    }

    function closeModal() {
        props.setModalVisible(false);
    }

    async function valider()
    {
        if (ppr !== '')
        {
            await axios.post('https://app-slfinance.fr/api/tags_ppr.php', qs.stringify({
                'create': true,
                'id_budget': props.user.id_budget,
                'intitule': ppr
            }))
            .then(async (e) => {
                
                if (e.data['status'] === 1) {

                    /* Recharge la liste des tag ppr pour ce budget */
                    await axios.post('https://app-slfinance.fr/api/tags_ppr.php', qs.stringify({
                        getTags: true,
                        id_budget: props.user.id_budget
                    }))
                    .then(async (result) => {
                        await props.setListeppr(result.data);
                        await setAff(!aff);
                    })

                }
            })
            .catch((e) => {
                console.log(e)
            })
        }
    }



    async function suppress()
    {
        if (pprToSuppress !== '') {
            console.log(pprToSuppress);
            await axios.post('https://app-slfinance.fr/api/tags_ppr.php', qs.stringify({
                'suppress': true,
                'id_budget': props.user.id_budget,
                'intitule': pprToSuppress
            }))
            .then(async (e) => {
                
                if (e.data['status'] === 1) {

                    /* Recharge la liste des tag ppr pour ce budget */
                    await axios.post('https://app-slfinance.fr/api/tags_ppr.php', qs.stringify({
                        getTags: true,
                        id_budget: props.user.id_budget
                    }))
                    .then(async (result) => {
                        await props.setListeppr(result.data);
                        await setAff(!aff);
                    })

                }
            })
            .catch((e) => {
                console.log(e)
            })
        }
    }

    return (
        <Modal
        isOpen={props.modalVisible}
        style={customStyles}
        ariaHideApp={false}
        onRequestClose={closeModal}
        >

            <Dialog
                setModalVisible={setDialogModalVisible}
                modalVisible={dialogModalVisible}
                callbackDialog={callbackDialog}
                message={"Confirmez vous la suppression?"}
            />

            <p style={{fontSize: 21}}>Gestion des politiques publiques</p>

            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 25}}>
                <input
                className='shadow-md p-2 rounded-md'
                style={{display: 'flex', padding: 5, border: '1px solid '+purple, borderRadius: 5, minWidth: 300}}
                onChange={(e) => {
                    setPpr(e.target.value);
                }}
                onBlur={(e) => {
                    // Nettoyer la chaîne avec la regex appropriée
                    var tmp = e.target.value

                    .replace(/[\t\n\r]+/g, " ") // Supprime les tabulations et retours à la ligne partout
                    .replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, ""); // Supprime espaces, tabulations en début/fin
                    
                    setPpr(tmp);
                    e.target.value = tmp;
                }}
                placeholder='Créer un tag'
                />

                <button
                    className='shadow-md rounded-md'
                    style={{backgroundColor: green, color: 'white', fontSize: 17, alignItems: 'center', justifyContent: 'center', display: 'flex', paddingLeft: 10, paddingRight: 10, marginLeft: 10}}
                    onClick={async () => {
                        valider();
                    }}
                >
                    <Check width="12" />
                    <p style={{marginLeft: 10}}>Valider</p>
                </button>
            </div>

            <div style={{flex: 1, margin: 25, overflowY: 'scroll', maxHeight: 500, padding: 10}}>
                {props.objet.map((elem, i) => (
                    <div key={i} style={{display: 'flex', flex: 1, backgroundColor: '#ffffff', margin: 5}} className='shadow-md rounded-md'>

                        <button
                            onClick={async() => {
                                await setPprToSuppress(elem[0]);
                                await setDialogModalVisible(true);
                            }}
                        >
                            <Trash width="50" />
                        </button>

                        <button
                        style={{flex: 1, margin: 2, borderRadius: 3, padding: 5, display: 'flex'}}
                        onClick={async() => {
                            await setPprToSuppress(elem[0]);
                            await setDialogModalVisible(true);
                        }}
                        >
                            <p>{elem}</p>
                        </button>
                    </div>
                ))}
            </div>
        
        </Modal>
    );
};

export default ModalGestionPPR;