import React, { useEffect, useState } from 'react';
import axios from 'axios';
import qs from 'qs';
import Access from "./components/Access";
import Navbar from "./components/Navbar";
import IncidencesFinancieres from "./components/IncidencesFinancieres";
import GanttSyntheseProjets from "./components/GanttSyntheseProjets";
import { addSpace, rescueHtml } from "./components/Tools";
import {NotificationManager} from 'react-notifications';
import { ReactComponent as Trash } from './Img/icon-trash.svg';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Add } from './Img/icon-add.svg';
import Dialog from "./components/Dialog";
import ModalGestionPPR from "./components/ModalGestionPPR";


/*
    La variable ListeProjets contient toutes les infos du projet et est structurée ainsi:
    projet[0] = id
    projet[1] = id_budget
    projet[2] = numero_projet
    projet[3] = nom
    projet[4] = enjeu
    projet[5] = priorite
    projet[6] = priorite_niveau
    projet[7] = pol_pub_rat (politique publique de rattachement)
    projet[8] = referent_elu
    projet[9] = montant
    projet[10] = description
    projet[11] = remarques
    projet[12] = maitre_ouvrage
    projet[13] = partenaire_mobiliser
    projet[14] = service_referent
    projet[15] = technicien_ref_nom
    projet[16] = technicien_ref_fonction
    projet[17] = technicien_ref_telephone
    projet[18] = technicien_ref_mail
    projet[19] = ressources_humaines
    projet[20] = etudes
    projet[21] = outils
    projet[22] = investissements
    projet[23] = incidences_organisationnelle_administrative
    projet[24] = h1
    projet[25] = h2
    projet[26] = h3
*/


const FichesProjets = (props) =>
{
    let history = useHistory();
    const [idSelected, setIdSelected] = useState(null);
    const [listeProjets, setListeProjets] = useState([]);
    const [aff, setAff] = useState(false);
    const [annees, setAnnees] = useState([]);
    const [ongletListeProjets, setOngletListeProjets] = useState(true);
    const [ongletIncidencesFinancieres, setOngletIncidencesFinancieres] = useState(false);
    const [ongletSyntheseGantt, setOngletSyntheseGantt] = useState(false);
    const [numero_projet, setNumero_projet] = useState(false);
    const [intitule, setIntitule] = useState(false);
    const [pol_pub_rat, setPol_pub_rat] = useState(false);
    const [montant, setMontant] = useState(false);
    const [ppr, setPpr] = useState('');
    const [listeppr, setListeppr] = useState([]);
    const [modalppr, setModalppr] = useState(false);
    
    const purple = '#6571FE';
    const blue = '#069BD3'
    const green = '#59B44A';
    
    const [suppressAll, setSuppressAll] = useState(false);
    const [dialogModalVisible, setDialogModalVisible] = useState(false);
    
    async function callbackDialog(ret) {
        if (ret === true) {
            if (idSelected === -1) {
                await listeProjets.forEach(async projet => {
                    await suppressProjet(projet[0]);
                });
                await setIdSelected(null);
            }
            else {
                await suppressProjet(idSelected);
                await setIdSelected(null);
            }
        }
    }
    

    async function create()
    {
        if (props.user.id_budget && props.user.id_budget !== null) {
            let numero = 0
            if (listeProjets.length !== 0) {
                listeProjets.forEach(projet => {
                    numero = projet[2]
                });
            }
            numero++;
            const params = {
                'creationFicheProjet': true,
                'id_budget': props.user.id_budget,
                'numero_projet': numero,
                'annees': annees
            }
            await axios.post('https://app-slfinance.fr/api/fiches_projets.php', qs.stringify(params))
            .then((result) => {
                if (result.data['status'] === 1) {
                    console.log(result.data.id_projet)
                    NotificationManager.success(result.data['status_message'], '', 700);
                    setTimeout(() => window.location = window.location.origin + '/saisie/prospective/ficheprojet/'+result.data.id_projet, 900);
                }
                else {
                    NotificationManager.warning(result.data['status_message'], '', 2000);
                }
            })
        }
    }

    async function suppressProjet(id)
    {
        const params = {
            'suppressProjet': true,
            'id': id
        }
        await axios.post('https://app-slfinance.fr/api/fiches_projets.php', qs.stringify(params))
        .then((result) => {
            if (result.data['status'] === 1) {
                NotificationManager.success(result.data['status_message'], '', 700);
                setTimeout(() => window.location = window.location.href, 900);
            }
            else {
                NotificationManager.warning(result.data['status_message'], '', 2000);
            }
        })
    }


    async function mount() {
        const params = {
            'getFichesProjets': true,
            'id_budget': props.user.id_budget
        }
        await axios.post('https://app-slfinance.fr/api/fiches_projets.php', qs.stringify(params))
        .then((result) => {
            setListeProjets(result.data)
        })

        /* Récupération de la liste des tag ppr pour ce budget */
        await axios.post('https://app-slfinance.fr/api/tags_ppr.php', qs.stringify({
            getTags: true,
            id_budget: props.user.id_budget
        }))
        .then((result) => {
            setListeppr(result.data);
        })

        /* Recuperation des annees de prospective */
        let prosp_an_deb = ''
        let prosp_an_fin = ''
        await axios.get('https://app-slfinance.fr/api/config_budget.php?id_budget='+props.user.id_budget)
        .then((result) => {
            result.data.forEach(element => {
                if (element[2] === 'prosp_an_deb') {
                    prosp_an_deb = parseInt(element[3])
                }
                if (element[2] === 'prosp_an_fin') {
                    prosp_an_fin = parseInt(element[3])
                }
            });
        }).catch((err) => {console.log(err)})
        
        if (prosp_an_deb === '' || prosp_an_fin === '')
        {
            await axios.get('https://app-slfinance.fr/api/application.php?access_token=StrategiesLocales')
            .then(async function (res) {
                if (res.data.prosp_an_deb && res.data.prosp_an_fin) {
                    if (prosp_an_deb === '') {
                        prosp_an_deb = parseInt(res.data.prosp_an_deb)
                    }
                    if (prosp_an_fin === '') {
                        prosp_an_fin = parseInt(res.data.prosp_an_fin)
                    }
                }
            })
            .catch((err) => {
                console.log(err)
            })
        }
        var liste_an = []
        var i = 0
        while (prosp_an_deb <= prosp_an_fin)
        {
            liste_an[i] = parseInt(prosp_an_deb)
            prosp_an_deb++;
            i++;
        }
        await setAnnees(liste_an);
    }

    
    // Récuperation de la liste des fiches projets
    useEffect(() => {
        if (props.user === null) {
            props.getUser()
        }
        else {
            mount();
        }
    }, [props.user])


    // Fonction qui permet de cocher ou décocher les hypotheses choisies.
    // A chaque changement de status, cela demande au serveur s'il peut changer le status de l'hypothese choisie, si le retour est positif,
    // alors ca change la case en checked.
    async function hypotheseChecked(id, h, status)
    {
        var tmp = listeProjets
        await tmp.forEach(async (projet, i) => {
            if (projet[0] === id) {
                tmp[i][h] = status
                const params = {
                    'updateProjet': true,
                    'projet': tmp[i]
                }
                await axios.post('https://app-slfinance.fr/api/fiches_projets.php', qs.stringify(params))
                .then(async (result) => {
                    if (result.data['status'] === 1) {
                        await setListeProjets(tmp)
                        await setAff(!aff)
                    }
                })
            }
        });
    }


    function sortBy(arr, criter) {
        // Index correspondant au critère
        const criterIndex = {
            id: 0,
            id_budget: 1,
            numero_projet: 2,
            intitule: 3,
            enjeu: 4,
            priorite: 5,
            priorite_niveau: 6,
            pol_pub_rat: 7,
            referent_elu: 8,
            montant: 9,
            description: 10,
            remarques: 11,
            maitre_ouvrage: 12,
            partenaire_mobiliser: 13,
            service_referent: 14,
            technicien_ref_nom: 15,
            technicien_ref_fonction: 16,
            technicien_ref_telephone: 17,
            technicien_ref_mail: 18,
            ressources_humaines: 19,
            etudes: 20,
            outils: 21,
            investissements: 22,
            incidences_organisationnelle_administrative: 23,
            h1: 24,
            h2: 25,
            h3: 26
        };
      
        // Utilisation de la méthode sort()
        return arr.sort((a, b) => {
            const valueA = a[criterIndex[criter]];
            const valueB = b[criterIndex[criter]];
    
            // Vérifie si les valeurs sont définies et non nulles
            if (valueA === undefined || valueB === undefined) {
                return 0; // Pas de tri si une des valeurs est undefined
            }
    
            // Convertir en nombre si on trie par id ou montant
            if (criter === 'id' || criter === 'id_budget' || criter === 'numero_projet' || criter === 'priorite_niveau' || criter === 'montant') {
                if (criter === 'numero_projet' && numero_projet) {
                    setNumero_projet(!numero_projet);
                    return parseInt(valueA) - parseInt(valueB);
                }
                else if (criter === 'numero_projet' && !numero_projet) {
                    setNumero_projet(!numero_projet);
                    return parseInt(valueB) - parseInt(valueA);
                }
                if (criter === 'montant' && montant) {
                    setMontant(!montant);
                    return parseFloat(valueA) - parseFloat(valueB);
                }
                else if (criter === 'montant' && !montant) {
                    setMontant(!montant);
                    return parseFloat(valueB) - parseFloat(valueA);
                }
            } else {
                if ((criter === 'intitule' && intitule) || (criter === 'pol_pub_rat' && pol_pub_rat)) {
                    if (criter === 'pol_pub_rat') {
                        setPol_pub_rat(!pol_pub_rat);
                    }
                    if (criter === 'intitule') {
                        setIntitule(!intitule);
                    }
                    return valueA.localeCompare(valueB);
                }
                else if ((criter === 'intitule' && !intitule) || (criter === 'pol_pub_rat' && !pol_pub_rat)) {
                    if (criter === 'pol_pub_rat') {
                        setPol_pub_rat(!pol_pub_rat);
                    }
                    if (criter === 'intitule') {
                        setIntitule(!intitule);
                    }
                    return valueB.localeCompare(valueA);
                }
            }
        });
    
      }


	return (
        <div>
            <Access needConnection={true} type_user={[0]}>
                <Navbar user={props.user} />

                <Dialog
                    setModalVisible={setDialogModalVisible}
                    modalVisible={dialogModalVisible}
                    callbackDialog={callbackDialog}
                    message={"Confirmez la suppression :"}
                />

                <Dialog
                    setModalVisible={setSuppressAll}
                    modalVisible={suppressAll}
                    callbackDialog={callbackDialog}
                    message={"Voulez vous supprimer toutes les fiches projet ?"}
                />

                <ModalGestionPPR
                    modalVisible={modalppr}
                    setModalVisible={setModalppr}
                    objet={listeppr}
                    setListeppr={setListeppr}
                    user={props.user}
                />


                <div style={{display: 'flex'}}>
                    <div style={{flex: 1, margin: 20, display: 'flex'}}>
                        <div style={{flex: 1, display: 'flex'}}>
                            <p>Prospective</p>
                            <p style={{marginLeft: 5}}>\</p>
                            <p style={{marginLeft: 5, color: purple, fontWeight: 'bold'}}>Fiches projets</p>
                        </div>
                        <div style={{flex: 1}}>
                            {props.user?
                            <p style={{flex: 1, textAlign: 'center'}}>{props.user.nom_budget}</p>
                            :null}
                        </div>
                        <div style={{flex: 1}}></div>
                    </div>
                </div>

                <div style={{flex: 1, margin: 20, display: 'flex'}}>
                    <div style={{flex: 1}}></div>
                    <button
                        className='shadow-md p-2 rounded-md'
                        style={ongletListeProjets?{backgroundColor: purple, color: 'white', fontSize: 17, margin: 5}:{backgroundColor: 'white', color: purple, fontSize: 17, margin: 5, border: '1px solid '+purple}}
                        onClick={() => {
                            setOngletListeProjets(true);
                            setOngletIncidencesFinancieres(false);
                            setOngletSyntheseGantt(false);
                        }}
                    >
                        Liste des projets
                    </button>

                    <button
                        className='shadow-md p-2 rounded-md'
                        style={ongletIncidencesFinancieres?{backgroundColor: purple, color: 'white', fontSize: 17, margin: 5}:{backgroundColor: 'white', color: purple, fontSize: 17, margin: 5, border: '1px solid '+purple}}
                        onClick={() => {
                            setOngletListeProjets(false);
                            setOngletIncidencesFinancieres(true);
                            setOngletSyntheseGantt(false);
                        }}
                    >
                        Incidences Financieres
                    </button>

                    <button
                        className='shadow-md p-2 rounded-md'
                        style={ongletSyntheseGantt?{backgroundColor: purple, color: 'white', fontSize: 17, margin: 5}:{backgroundColor: 'white', color: purple, fontSize: 17, margin: 5, border: '1px solid '+purple}}
                        onClick={() => {
                            setOngletListeProjets(false);
                            setOngletIncidencesFinancieres(false);
                            setOngletSyntheseGantt(true);
                        }}
                    >
                        Synthèse des projets (GANTT)
                    </button>
                    <div style={{flex: 1}}></div>
                </div>


                {ongletListeProjets?
                <div style={{display: 'flex'}}>

                    <div style={{flex: 1}}></div>

                    <div>
                        <button
                            className='shadow-md rounded-md'
                            style={{flex: 1, backgroundColor: blue, color: 'white', fontSize: 14, margin: 10}}
                            onClick={() => {
                                setModalppr(true);
                            }}
                        >
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft:10, paddingRight: 10}}>
                                <Add width="12" />
                                <p style={{marginLeft: 5}}>Gérer les politiques publiques</p>
                            </div>
                        </button>
                    </div>

                    <div style={{flex: 1}}></div>
                    <select
                    className='shadow-md'
                    style={{flex: 1, padding: 5, margin: 15}}
                    value={ppr}
                    onChange={async(e) => {
                        setPpr(e.target.value);
                    }}
                    >
                        <option value=''>Politique publique de rattachement</option>
                        {listeppr.map((elem, i) =>
                        <option key={i} value={elem}>{elem}</option>
                        )}
                    </select>
                    <div style={{flex: 1}}></div>

                    <div>
                        <button
                            className='shadow-md rounded-md'
                            style={{flex: 1, backgroundColor: green, color: 'white', fontSize: 14, margin: 10}}
                            onClick={() => {
                                create()
                            }}
                        >
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft:10, paddingRight: 10}}>
                                <Add width="12" />
                                <p style={{marginLeft: 5}}>Créer un projet</p>
                            </div>
                        </button>
                    </div>

                    <div style={{flex: 1}}></div>
                    
                </div>
                :null}


                {ongletListeProjets?
                <div style={{flex: 1, textAlign: 'center'}}>

                    <div style={{flex: 1, display: 'flex'}}>

                        <div style={{flex: 1, margin: 15}}>

                            <div style={{display: 'flex', margin: 10}}>
                                
                                <button
                                    onClick={async() => {
                                        await setIdSelected(-1)
                                        await setSuppressAll(true)
                                    }}
                                >
                                    <Trash width="50" />
                                </button>
                                
                                <button
                                style={{flex: 0.1, minWidth: 100}}
                                onClick={async () => {
                                    await setListeProjets(sortBy(listeProjets, 'numero_projet'));
                                    await setAff(!aff);
                                }}
                                >
                                    <p>Numéro</p>
                                </button>
                                
                                <button
                                style={{flex: 0.1, minWidth: 100}}
                                onClick={async () => {
                                    // await setListeProjets(sortBy(listeProjets, 'color'));
                                    // await setAff(!aff);
                                }}
                                >
                                    <p>État</p>
                                </button>
                                
                                <button
                                style={{flex: 1}}
                                onClick={async () => {
                                    await setListeProjets(sortBy(listeProjets, 'intitule'));
                                    await setAff(!aff);
                                }}
                                >
                                    <p>Intitulé du projet</p>
                                </button>

                                <button
                                style={{flex: 0.5, textAlign: 'center', marginRight: 50}}
                                onClick={async () => {
                                    await setListeProjets(sortBy(listeProjets, 'pol_pub_rat'));
                                    await setAff(!aff);
                                }}
                                >
                                    <p>Politique publique</p>
                                </button>

                                <button
                                style={{flex: 0.5, textAlign: 'right', marginRight: 50}}
                                onClick={async () => {
                                    await setListeProjets(sortBy(listeProjets, 'montant'));
                                    await setAff(!aff);
                                }}
                                >
                                    <p>Montant du projet</p>
                                </button>
                                <p style={{flex: 0.1}}>H1</p>
                                <p style={{flex: 0.1}}>H2</p>
                                <p style={{flex: 0.1}}>H3</p>
                            </div>

                            {/* Listing des fiches projets */}
                            {listeProjets.map((projet, i) =>
                            (projet[7] && projet[7].includes(ppr)) || !ppr?
                            <div className='shadow-xl rounded-sm' key={i} style={{display: 'flex', margin: 10, backgroundColor: '#ffffff'}}>
                                
                                <button
                                    onClick={async() => {
                                        await setIdSelected(projet[0])
                                        await setDialogModalVisible(true)
                                    }}
                                >
                                    <Trash width="50" />
                                </button>

                                <button
                                className='shadow-sm'
                                style={{flex: 0.1, minWidth: 100}}
                                onClick={() => {
                                    history.push('/saisie/prospective/ficheprojet/'+projet[0])
                                }}
                                >
                                    <p>{rescueHtml(projet[2])}</p>
                                </button>

                                <button
                                className='shadow-sm'
                                style={{display: 'flex', flex: 0.1, minWidth: 100}}
                                onClick={() => {
                                    history.push('/saisie/prospective/ficheprojet/'+projet[0])
                                }}
                                >
                                    <div style={{flex: 1}}></div>
                                    <div style={{flex: 1}}>
                                        <div style={{margin: 2, justifyContent: 'center', alignItems: 'center', display: 'flex', border: '1px solid '+projet[27]?projet[27]:'#ffffff', borderRadius: 100, backgroundColor: projet[27]?projet[27]:'#ffffff', minWidth: 20, minHeight: 20, maxWidth: 20, maxHeight: 20}}></div>
                                    </div>
                                    <div style={{flex: 1}}></div>
                                </button>

                                <button
                                className='shadow-sm'
                                style={{flex: 1}}
                                onClick={() => {
                                    history.push('/saisie/prospective/ficheprojet/'+projet[0])
                                }}
                                >
                                    <p>{rescueHtml(projet[3])}</p>
                                </button>

                                <button
                                className='shadow-sm'
                                style={{flex: 0.5, textAlign: 'center', paddingRight: 50}}
                                onClick={() => {
                                    history.push('/saisie/prospective/ficheprojet/'+projet[0])
                                }}
                                >
                                    <p>{projet[7]?projet[7].replace('|', ' '):null}</p>
                                </button>

                                <button
                                className='shadow-sm'
                                style={{flex: 0.5, textAlign: 'right', paddingRight: 50}}
                                onClick={() => {
                                    history.push('/saisie/prospective/ficheprojet/'+projet[0])
                                }}
                                >
                                    <p>{projet[9]?addSpace(parseFloat(projet[9]))+' €':null}</p>
                                </button>

                                <p className='shadow-sm' style={{flex: 0.1}}>{<input type="checkbox" checked={projet[24] === "0"?false:true} onChange={() => hypotheseChecked(projet[0], 24, projet[24] === "0"?"1":"0")}/>}</p>
                                <p className='shadow-sm' style={{flex: 0.1}}>{<input type="checkbox" checked={projet[25] === "0"?false:true} onChange={() => hypotheseChecked(projet[0], 25, projet[25] === "0"?"1":"0")}/>}</p>
                                <p className='shadow-sm' style={{flex: 0.1}}>{<input type="checkbox" checked={projet[26] === "0"?false:true} onChange={() => hypotheseChecked(projet[0], 26, projet[26] === "0"?"1":"0")}/>}</p>
                            </div>
                            :null)}
                        </div>

                    </div>
                </div>
                :null}


                {ongletIncidencesFinancieres?
                <div style={{flex: 1, textAlign: 'center', marginTop: 10, marginLeft: 30, marginRight: 30, marginBottom: 30}}>
                    {props.user !== null && annees.length > 0?
                    <IncidencesFinancieres user={props.user} annees={annees} aff={aff} />
                    :null
                    }
                </div>
                :null}


                {ongletSyntheseGantt?
                <div style={{flex: 1, textAlign: 'center', marginTop: 10, marginLeft: 30, marginRight: 30, marginBottom: 30}}>
                    <GanttSyntheseProjets user={props.user} listeProjets={listeProjets} />
                </div>
                :null}

            </Access>
        </div>
	);
}

export default FichesProjets;
